import { routerTransitions } from './../_global/_animations/router-transitions.animation';
import { RouterOutlet } from '@angular/router';
import { MatDrawerMode } from '@angular/material/sidenav';
import { SidenavService } from './sidenav/sidenav.service';
import { LayoutService } from './layout.service';
import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';

@Component({
    selector: 'app-layout',
    templateUrl: './layout.component.html',
    styleUrls: ['./layout.component.scss'],
    animations: [routerTransitions],
})
export class LayoutComponent implements OnInit, OnDestroy {
    headerPosition$ = this.layoutService.headerPosition$;
    footerPosition$ = this.layoutService.footerPosition$;

    sidenavMode$: MatDrawerMode = 'side';
    sidenavOpen$ = this.sidenavService.open$;
    sidenavCollapsed$ = this.sidenavService.collapsed$;
    sidenavExpanded$ = this.sidenavService.expanded$;

    constructor(private layoutService: LayoutService, private sidenavService: SidenavService) {}

    ngOnInit(): void {}

    ngOnDestroy(): void {}

    closeSidenav() {
        this.sidenavService.close();
    }

    prepareRoute(outlet: RouterOutlet) {
        return outlet?.activatedRouteData?.['animation'];
    }
}
