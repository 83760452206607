import { HttpClient } from '@angular/common/http';
import { Injectable, OnDestroy } from '@angular/core';
import { ActivatedRoute, Router, UrlSerializer, UrlTree } from '@angular/router';
import * as moment from 'moment';
import { BlockUI, NgBlockUI } from 'ng-block-ui';
import { BehaviorSubject, Observable, ReplaySubject, Subscription, timer } from 'rxjs';
import { map, takeUntil, timeout } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { GsisUser } from './gsis-user.interface';

@Injectable({
    providedIn: 'root',
})
export class GsisService implements OnDestroy {
    @BlockUI() blockUI: NgBlockUI;

    private destroyed$ = new ReplaySubject<void>(1);

    private _user = new BehaviorSubject<GsisUser>(JSON.parse(localStorage.getItem('gsisUser')) as GsisUser);
    user$ = this._user.asObservable();
    get user(): GsisUser {
        return this._user.value;
    }

    private sessionTimeoutSubscription: Subscription;
    get sessionExpired() {
        if (!this.user) return true;
        return this.user.expiresAt ? moment().isSameOrAfter(moment(this.user.expiresAt)) : false;
    }

    private demoCitizen: GsisUser = {
        lastName: 'Demo',
        firstName: 'Πολίτης',
        tin: '000111222',

        expiresAt: moment().add(30, 'minutes').toDate(),
    };

    private demoInstructor: GsisUser = {
        lastName: 'Demo',
        firstName: 'Εκπαιδευτής',
        tin: '222111000',

        expiresAt: moment().add(30, 'minutes').toDate(),
    };

    constructor(private router: Router, private activatedRoute: ActivatedRoute, private http: HttpClient) {
        this.initSessionTimeout();
    }

    ngOnDestroy(): void {
        this.destroyed$.next(undefined);
        this.destroyed$.complete();
    }

    gsisLogin(): void {
        if (environment.production) {
            // redirect to taxis net
            const gsisRedirect =
                environment.gsis.baseUrl +
                environment.gsis.authorize +
                '?scope=' +
                environment.gsis.scope +
                '&response_type=' +
                environment.gsis.responseType +
                '&approval_prompt=' +
                environment.gsis.approval +
                '&redirect_uri=' +
                environment.gsis.redirectUrl +
                '&client_id=' +
                environment.gsis.clientId;
            window.location.href = encodeURI(gsisRedirect);
        } else {
            // demo user
            this.blockUI.start();
            this.setGsisUser(this.demoInstructor);
            setTimeout(() => {
                this.router.navigate([this.redirect]);
                this.blockUI.stop();
            }, 500);
        }
    }

    async gsisLogout() {
        this.clearUser();
        let currentUrl = this.router.url;
        this.router.navigateByUrl('/', { skipLocationChange: true }).then(() => {
            this.router.navigate([currentUrl]);
        });
    }

    async gsisCallback(code: string) {
        this.blockUI.start();
        await this.retrieveAndSetUser(code);

        // handle login redirect
        setTimeout(() => {
            this.router.navigate([this.redirect]);
            this.blockUI.stop();
        });
    }

    userIsSet(): Observable<boolean> {
        return this.user$.pipe(
            map((user) => {
                return !!user;
            })
        );
    }

    private clearUser(): void {
        localStorage.removeItem('gsisUser');
        this._user.next(null);
    }

    private initSessionTimeout(): void {
        // unsubscribe from existing session timeout
        if (this.sessionTimeoutSubscription) {
            this.sessionTimeoutSubscription.unsubscribe();
        }

        // watch user to set session timeout
        this.user$.pipe(takeUntil(this.destroyed$)).subscribe((user) => {
            if (!user) return; // if user is not set, do not initialize session timeout
            if (this.sessionExpired) this.gsisLogout(); // if session already expired, logout
            if (!user.expiresAt) return; // if user does not have session expiration date, do not initialize session timeout

            // init session timeout
            console.log(`user session will expire at ${moment(user.expiresAt).format('HH:mm:ss')}`);
            this.sessionTimeoutSubscription = timer(moment(user.expiresAt).toDate()).subscribe(() => {
                this.gsisLogout();
            });
        });
    }

    private setGsisUser(user: GsisUser): void {
        localStorage.setItem('gsisUser', JSON.stringify(user));
        this._user.next(user);
    }

    private async retrieveAndSetUser(code: string) {
        await this.http
            .get(environment.backend + '/gsis/user', {
                params: {
                    govUid: environment.config.govUid,
                    code: code,
                },
            })
            .toPromise()
            .then((response: any) => {
                let user: GsisUser = response;
                this.setGsisUser(user);
            });
    }

    private get redirect(): string {
        let r = JSON.parse(localStorage.getItem('redirect'));
        localStorage.removeItem('redirect');
        if (r && moment().isBefore(moment(r.expiresAt))) {
            return r.url;
        } else {
            return '/';
        }
    }
}
