export const environment = {
    production: true,

    name: 'Booking | GOV',
    version: '0.0.1',

    frontend: 'https://booking.pde.gov.gr',
    backend: 'https://booking.open1.eu/api',

    config: {
        organization: 'Περιφέρεια Δυτικής Ελλάδας',
        govUid: 'pde',
        logo: 'pde-logo.png',
        website: 'https://www.pde.gov.gr/',
        termsOfService: 'assets/terms/pde-terms.pdf',
        homepageTitle: 'Ψηφιακό ραντεβού για τις Διευθύνσεις Μεταφορών και Επικοινωνιών της Περιφέρειας Δυτικής Ελλάδας',
        links: [
            { label: 'Όροι Χρήσης', link: 'assets/terms/pde-terms.pdf' },
            { label: 'Απαραίτητα Δικαιολογητικά', link: 'https://politis.pde.gov.gr/αναζήτηση-κατά-γενική-διεύθυνση/' },
        ],
    },

    gsis: {
        baseUrl: 'https://oauth2.gsis.gr/oauth2server',
        redirectUrl: 'https://booking.pde.gov.gr/login/callback',
        authorize: '/oauth/authorize',
        clientId: 'L2GX1614509',
        scope: 'read',
        responseType: 'code',
        approval: 'auto',
    },
};
