import { Component, OnInit } from '@angular/core';

@Component({
    selector: 'block-ui-custom',
    templateUrl: './block-ui-custom.component.html',
    styleUrls: ['./block-ui-custom.component.scss'],
})
export class BlockUiCustomComponent implements OnInit {
    constructor() {}

    message: any;

    ngOnInit(): void {}
}
