import { DOCUMENT } from '@angular/common';
import { Inject, Injectable, Renderer2, RendererFactory2 } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { map } from 'rxjs/operators';

export type Theme = '' | 'toitsu-ng-default' | 'toitsu-ng-gov';

@Injectable({
    providedIn: 'root',
})
export class ThemeService {
    renderer: Renderer2;

    private _themeSubject = new BehaviorSubject<[Theme, Theme]>(['', 'toitsu-ng-default']);
    theme$ = this._themeSubject.asObservable();

    private _isDarkTheme = new BehaviorSubject<boolean>(false);
    isDarkTheme$: Observable<boolean> = this._isDarkTheme.asObservable();

    activeTheme$ = this.theme$.pipe(
        map((themes) => {
            return themes[1];
        })
    );

    constructor(@Inject(DOCUMENT) private document: Document, private _renderer: RendererFactory2) {
        this.renderer = _renderer.createRenderer(null, null);
        this.renderTheme();

        this._isDarkTheme.next(this.document.body.classList.contains('dark'));
    }

    setTheme(theme: Theme) {
        let previousTheme = this._themeSubject.getValue()[1];
        this._themeSubject.next([previousTheme, theme]);
    }

    setDarkTheme(setDark: boolean) {
        if (setDark) {
            this.darkModeOn();
        } else {
            this.darkModeOff();
        }
    }

    toggleDarkMode() {
        if (this.document.body.classList.contains('dark')) {
            this.darkModeOff();
        } else {
            this.darkModeOn();
        }
    }

    private darkModeOn() {
        this.renderer.addClass(this.document.body, 'dark');
        this._isDarkTheme.next(true);
        localStorage.setItem('dark-theme', 'true');
    }

    private darkModeOff() {
        this.renderer.removeClass(this.document.body, 'dark');
        this._isDarkTheme.next(false);
        localStorage.setItem('dark-theme', 'false');
    }

    private renderTheme() {
        this.theme$.subscribe((theme) => {
            if (theme[0] !== '') {
                // remove previous theme
                this.renderer.removeClass(this.document.body, theme[0]);
            }
            // add selected theme
            this.renderer.addClass(this.document.body, theme[1]);
        });
    }
}
