import { SidenavService } from './sidenav.service';
import { map, takeUntil } from 'rxjs/operators';
import { LayoutService } from './../layout.service';
import { Component, HostBinding, HostListener, Input, OnDestroy, OnInit } from '@angular/core';
import { Observable } from 'rxjs';
import { componentDestroyed } from 'src/app/_global/_util/component-destroyed';

@Component({
    selector: 'toitsu-ng-material-sidenav',
    templateUrl: './sidenav.component.html',
    styleUrls: ['./sidenav.component.scss'],
})
export class SidenavComponent implements OnInit, OnDestroy {
    @Input()
    @HostBinding('class.collapsed')
    collapsed!: boolean | null;

    @Input()
    @HostBinding('class.expanded')
    expanded!: boolean | null;

    @HostListener('mouseenter')
    @HostListener('touchenter')
    onMouseEnter() {
        this.sidenavService.expandSidenav(true);
    }

    @HostListener('mouseleave')
    @HostListener('touchleave')
    onMouseLeave() {
        this.sidenavService.expandSidenav(false);
    }

    showHeader$: Observable<boolean> = this.layoutService.headerPosition$.pipe(
        map((headerPosition) => {
            return headerPosition !== 'above';
        })
    );

    constructor(private layoutService: LayoutService, private sidenavService: SidenavService) {}

    ngOnInit(): void {}

    ngOnDestroy(): void {}
}
