<a class="sidenav-header" href="https://www.ots.gr" target="_blank">

    <div class="app-logo">
        <div class="orbit-spinner">
            <div class="orbit"></div>
            <div class="orbit"></div>
            <div class="orbit"></div>
        </div>
    </div>

    <span class="app-name">{{appName}}</span>

</a>
