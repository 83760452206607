import { environment } from './../../../../environments/environment';
import { Component, OnInit } from '@angular/core';

@Component({
    selector: 'toitsu-ng-material-sidenav-header',
    templateUrl: './sidenav-header.component.html',
    styleUrls: ['./sidenav-header.component.scss'],
})
export class SidenavHeaderComponent implements OnInit {
    appName = environment.name;

    constructor() {}

    ngOnInit(): void {}
}
