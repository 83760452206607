import { HttpEvent, HttpHandler, HttpHeaders, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable({
    providedIn: 'root',
})
export class RequestInterceptorService implements HttpInterceptor {
    intercept(request: HttpRequest<any>, handler: HttpHandler): Observable<HttpEvent<any>> {
        // if request is not to backend, return as was
        if (!request.url.startsWith(environment.backend)) return handler.handle(request);

        // add Accept-Language header
        let headers: HttpHeaders = request.headers;
        headers = headers.append('Accept-Language', 'el');

        // clone request and handle
        const clonedRequest = request.clone({ headers: headers });
        return handler.handle(clonedRequest);
    }
}
