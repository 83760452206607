import { Directive } from "@angular/core";

@Directive({
    selector: "[appAppPageContainer], app-page-container, .app-page-container",
    host: {
        class: "app-page-container",
    },
})
export class AppPageContainerDirective {
    constructor() {}
}
