import { HeaderLanguageComponent } from './header-language/header-language.component';
import { HeaderOrganizationComponent } from './header-organization/header-organization.component';
import { SharedModule } from '../../_global/_shared.module';
import { HeaderComponent } from './header.component';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HeaderDarkModeComponent } from './header-dark-mode/header-dark-mode.component';
import { HeaderUserComponent } from './header-user/header-user.component';
import { RouterModule } from '@angular/router';

@NgModule({
    declarations: [
        HeaderComponent,
        HeaderOrganizationComponent,
        HeaderLanguageComponent,
        HeaderDarkModeComponent,
        HeaderUserComponent,
    ],
    imports: [CommonModule, SharedModule, RouterModule],
    exports: [HeaderComponent],
})
export class HeaderModule {}
