import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { GsisService } from './gsis.service';

@Injectable({
    providedIn: 'root',
})
export class AuthService {
    user$ = this.gsis.user$;
    get user() {
        return this.gsis.user;
    }

    constructor(private gsis: GsisService) {}

    login() {
        this.gsis.gsisLogin();
    }

    logout() {
        this.gsis.gsisLogout();
    }

    isLoggedIn(): Observable<boolean> {
        return this.gsis.userIsSet();
    }
}
