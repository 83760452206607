<div class="footer-divider"></div>
<div class="footer">
    <app-page-container>
        <div class="footer-content">
            <div fxLayout="row" fxLayout.lt-md="column" fxLayoutAlign="start center" fxLayoutAlign.lt-md="start start"
                fxFlex="grow">

                <div fxLayout="column">
                    <ul class="footer-links">
                        <li class="footer-link" *ngFor="let link of links">
                            <a [href]="link.link" target="_blank">{{link.label}}</a>
                        </li>
                    </ul>

                    <div fxLayout="column" class="org">
                        <span class="mat-body-2" style="line-height: 2;">Επιχειρησιακός φορέας</span>
                        <div fxLayout="row">
                            <a [href]="website" class="image-link">
                                <img class="org-logo" alt="logo" [src]="logo">
                            </a>
                        </div>
                    </div>
                </div>

                <div fxFlex></div>

                <a href="https://gov.gr" class="image-link gov-svg">
                    <img src="assets/images/logos/government-logo.svg" alt="gov">
                </a>
            </div>

            <div fxLayout="row">
                <span class="mat-caption">&copy; Copyright {{currentYear}} - Υλοποίηση από την <a
                        href="https://ots.gr">OTS</a></span>
            </div>
        </div>
    </app-page-container>
</div>