import { SharedModule } from 'src/app/_global/_shared.module';
import { HttpClient, HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { BlockUiCustomComponent } from './_global/_components/vendor/block-ui/block-ui-custom.component';
import { LayoutModule } from './_layout/layout.module';
import { NgModule } from '@angular/core';
import { BrowserModule, HammerModule, HAMMER_GESTURE_CONFIG } from '@angular/platform-browser';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { BlockUIModule } from 'ng-block-ui';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { MatFormFieldDefaultOptions, MAT_FORM_FIELD_DEFAULT_OPTIONS } from '@angular/material/form-field';
import { MatSnackBarConfig, MAT_SNACK_BAR_DEFAULT_OPTIONS } from '@angular/material/snack-bar';
import { MAT_DATE_LOCALE } from '@angular/material/core';
import { RequestInterceptorService } from './_global/_auth/request.interceptor';

// AoT requires an exported function for factories
export function HttpLoaderFactory(http: HttpClient) {
    return new TranslateHttpLoader(http);
}

@NgModule({
    declarations: [AppComponent, BlockUiCustomComponent],
    bootstrap: [AppComponent],
    imports: [
        // Angular Core (do not touch)
        BrowserModule,
        BrowserAnimationsModule,
        HttpClientModule,

        // routing
        AppRoutingModule,

        // Translation
        TranslateModule.forRoot({
            loader: {
                provide: TranslateLoader,
                useFactory: HttpLoaderFactory,
                deps: [HttpClient],
            },
        }),

        // Register a Service Worker (optional)
        // ServiceWorkerModule.register('ngsw-worker.js', {
        //     enabled: environment.production,
        // }),

        // Layout
        LayoutModule,

        // Third Party Modules
        BlockUIModule.forRoot({ template: BlockUiCustomComponent }),
        HammerModule,

        // Toitsu Ng Modules
        SharedModule,
    ],
    providers: [
        // Material Form Fields Provider
        {
            provide: MAT_FORM_FIELD_DEFAULT_OPTIONS,
            useValue: {
                appearance: 'outline',
            } as MatFormFieldDefaultOptions,
        },
        // Material Snack Bar Provider
        {
            provide: MAT_SNACK_BAR_DEFAULT_OPTIONS,
            useValue: {
                duration: 5000,
                horizontalPosition: 'end',
                verticalPosition: 'top',
            } as MatSnackBarConfig,
        },
        // Date Locale Provider
        { provide: MAT_DATE_LOCALE, useValue: 'el-EL' },
        // Http Interceptor Provider
        {
            provide: HTTP_INTERCEPTORS,
            useClass: RequestInterceptorService,
            multi: true,
        },
    ],
})
export class AppModule {}
