import { MatTooltipModule } from '@angular/material/tooltip';
import { MatIconModule } from '@angular/material/icon';
import { MatButtonModule } from '@angular/material/button';
import { SharedModule } from 'src/app/_global/_shared.module';
import { RouterModule } from '@angular/router';
import { AppPageContainerDirective } from './app-page-container/app-page-container.directive';
import { AppPageDirective } from './app-page.directive';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';
import { AppPageHeaderComponent } from './app-page-header/app-page-header.component';

@NgModule({
    declarations: [AppPageDirective, AppPageHeaderComponent, AppPageContainerDirective],
    imports: [CommonModule, RouterModule, TranslateModule, MatButtonModule, MatIconModule, MatTooltipModule],
    exports: [AppPageDirective, AppPageHeaderComponent, AppPageContainerDirective],
})
export class AppPageModule {}
