import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
    providedIn: 'root',
})
export class LayoutService {
    private _headerPositionSubject = new BehaviorSubject<string>('static');
    headerPosition$ = this._headerPositionSubject.asObservable();

    private _footerPositionSubject = new BehaviorSubject<string>('static');
    footerPosition$ = this._footerPositionSubject.asObservable();

    constructor() {}

    setheaderPosition(position: 'above' | 'fixed' | 'static') {
        this._headerPositionSubject.next(position);
    }

    setFooterPosition(position: 'above' | 'fixed' | 'static') {
        this._footerPositionSubject.next(position);
    }
}
