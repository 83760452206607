<mat-sidenav-container fullscreen>
    <mat-sidenav-content class="layout" fxLayout="column">
        <!-- HEADER (ABOVE) -->
        <ng-container *ngIf="(headerPosition$ | async) === 'above'">
            <toitsu-ng-material-header></toitsu-ng-material-header>
        </ng-container>

        <mat-sidenav-container autosize fxFlex (backdropClick)="closeSidenav()">
            <!-- SIDENAV -->
            <mat-sidenav [opened]="sidenavOpen$ | async" [mode]="sidenavMode$"
                class="toitsu-ng-material-collapsible-sidenav">
                <toitsu-ng-material-sidenav [collapsed]="sidenavCollapsed$ | async"
                    [expanded]="sidenavExpanded$ | async"></toitsu-ng-material-sidenav>
            </mat-sidenav>

            <!-- CONTENT -->
            <mat-sidenav-content class="content-container" fxLayout="column">
                <!-- HEADER (FIXED) -->
                <ng-container *ngIf="(headerPosition$ | async) === 'fixed'">
                    <toitsu-ng-material-header></toitsu-ng-material-header>
                </ng-container>

                <div id="layout-content" class="content-inner" fxLayout="column" fxFlex="auto">
                    <!-- HEADER (STATIC) -->
                    <ng-container *ngIf="(headerPosition$ | async) === 'static'">
                        <toitsu-ng-material-header></toitsu-ng-material-header>
                    </ng-container>

                    <!-- APP CONTENT -->
                    <div fxFlex="auto" id="appContent">
                        <div [@routerTransitions]="prepareRoute(outlet)">
                            <router-outlet #outlet="outlet"></router-outlet>
                        </div>
                    </div>

                    <!-- FOOTER (STATIC) -->
                    <ng-container *ngIf="(footerPosition$ | async) === 'static'">
                        <toitsu-ng-material-footer></toitsu-ng-material-footer>
                    </ng-container>
                </div>

                <!-- FOOTER (FIXED) -->
                <ng-container *ngIf="(footerPosition$ | async) === 'fixed'">
                    <toitsu-ng-material-footer></toitsu-ng-material-footer>
                </ng-container>
            </mat-sidenav-content>
        </mat-sidenav-container>

        <!-- FOOTER (ABOVE) -->
        <ng-container *ngIf="(footerPosition$ | async) === 'above'">
            <toitsu-ng-material-footer></toitsu-ng-material-footer>
        </ng-container>
    </mat-sidenav-content>
</mat-sidenav-container>