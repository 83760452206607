import { Component, OnInit } from '@angular/core';
import { AuthService } from 'src/app/_global/_auth/auth.service';
import { GsisUser } from 'src/app/_global/_auth/gsis-user.interface';
import { headerUserDropdownAnimation } from './header-user-dropdown.animation';

@Component({
    selector: 'toitsu-ng-material-header-user',
    templateUrl: './header-user.component.html',
    styleUrls: ['./header-user.component.scss'],
    animations: [headerUserDropdownAnimation],
})
export class HeaderUserComponent implements OnInit {
    isOpen: boolean = false;
    user: GsisUser;

    get dropdownState() {
        return this.isOpen ? 'open' : 'closed';
    }

    constructor(private authService: AuthService) {
        this.authService.user$.subscribe((user) => {
            this.user = user;
        });
    }

    ngOnInit(): void {}

    toggleDropdown() {
        this.isOpen = !this.isOpen;
    }

    onClickOutside() {
        this.isOpen = false;
    }

    logout() {
        this.isOpen = false;
        this.authService.logout();
    }
}
