import { SidenavModule } from './sidenav/sidenav.module';
import { RouterModule } from '@angular/router';
import { FooterModule } from './footer/footer.module';
import { HeaderModule } from './header/header.module';
import { SharedModule } from './../_global/_shared.module';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { LayoutComponent } from './layout.component';

@NgModule({
    declarations: [LayoutComponent],
    imports: [CommonModule, RouterModule, SharedModule, HeaderModule, SidenavModule, FooterModule],
})
export class LayoutModule {}
