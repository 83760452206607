import { Component, OnDestroy, OnInit } from '@angular/core';
import * as moment from 'moment';
import { environment } from 'src/environments/environment';

@Component({
    selector: 'toitsu-ng-material-footer',
    templateUrl: './footer.component.html',
    styleUrls: ['./footer.component.scss'],
})
export class FooterComponent implements OnInit, OnDestroy {
    logo = `assets/images/logos/${environment.config.logo}`;
    website = environment.config.website || '/';
    links = environment.config.links;

    currentYear = moment().year();

    constructor() {}

    ngOnInit(): void {}

    ngOnDestroy(): void {}
}
