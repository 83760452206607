import { animate, animateChild, group, query, style, transition, trigger } from '@angular/animations';

export const routerTransitions = trigger('routerTransitions', [
    transition('* => CreateEditEducationItem', [
        style({ position: 'relative' }),
        query(':enter, :leave', [
            style({
                position: 'absolute',
                top: 0,
                left: 0,
                width: '100%',
                height: 'calc(100vh - 64px - 56px)',
            }),
        ]),
        query(':enter', [style({ left: '100%', zIndex: '100' })]), // backgroundColor: "rgb(250, 250, 250)",
        group([query(':enter', [animate('1s 300ms ease', style({ left: '0%' }))])]),
        // query(":leave", animateChild()),
    ]),
    transition('CreateEditEducationItem => *', [
        style({ position: 'relative' }),
        query(':enter, :leave', [
            style({
                position: 'absolute',
                top: 0,
                left: 0,
                width: '100%',
                height: 'calc(100vh - 64px - 56px)',
            }),
        ]),
        query(':leave', [style({ left: '0%', zIndex: '100' })]), // backgroundColor: "rgb(250, 250, 250)",
        group([query(':leave', [animate('1s 300ms ease', style({ left: '100%' }))])]),
        // query(":enter", animateChild()),
    ]),
]);
