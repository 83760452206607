import { RouterModule } from '@angular/router';
import { SharedModule } from './../../_global/_shared.module';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SidenavComponent } from './sidenav.component';
import { SidenavHeaderComponent } from './sidenav-header/sidenav-header.component';

@NgModule({
    declarations: [SidenavComponent, SidenavHeaderComponent],
    imports: [CommonModule, RouterModule, SharedModule],
    exports: [SidenavComponent],
})
export class SidenavModule {}
