import { LayoutService } from './../layout.service';
import { Injectable, OnDestroy } from '@angular/core';
import { MediaObserver } from '@angular/flex-layout';
import { NavigationEnd, Router } from '@angular/router';
import { BehaviorSubject, ReplaySubject } from 'rxjs';
import { filter, map, takeUntil } from 'rxjs/operators';
import { componentDestroyed } from 'src/app/_global/_util/component-destroyed';

@Injectable({
    providedIn: 'root',
})
export class SidenavService implements OnDestroy {
    private destroyed$ = new ReplaySubject<void>(1);

    private mobileBreakpoint = 'lt-md';

    private _openSubject = new BehaviorSubject<boolean>(false);
    open$ = this._openSubject.asObservable();

    private _modeSubject = new BehaviorSubject<'side' | 'over'>(this.media.isActive(this.mobileBreakpoint) ? 'over' : 'side');
    mode$ = this._modeSubject.asObservable();

    private _collapsedSubject = new BehaviorSubject<boolean>(false);
    collapsed$ = this._collapsedSubject.asObservable();

    private _expandedSubject = new BehaviorSubject<boolean>(false);
    expanded$ = this._expandedSubject.asObservable();

    get collapsed() {
        return this._collapsedSubject.getValue();
    }

    get expanded() {
        return this._expandedSubject.getValue();
    }

    constructor(private router: Router, private media: MediaObserver, private layoutService: LayoutService) {
        // this.media
        //     .asObservable()
        //     .pipe(
        //         map(() => this.media.isActive(this.mobileBreakpoint)),
        //         takeUntil(componentDestroyed(this))
        //     )
        //     .subscribe((isMobile) => {
        //         if (isMobile) {
        //             this._modeSubject.next('over');
        //             this._openSubject.next(false);
        //             this._collapsedSubject.next(false);
        //             // this.layoutService.setFooterPosition('above');
        //         } else {
        //             this._modeSubject.next('side');
        //             // this._openSubject.next(true);
        //             this._openSubject.next(false);
        //             // this.layoutService.setFooterPosition('fixed');
        //         }
        //     });
        // this.router.events
        //     .pipe(
        //         filter((event) => event instanceof NavigationEnd),
        //         takeUntil(componentDestroyed(this))
        //     )
        //     .subscribe((event) => {
        //         // Close Sidenav on Mobile after Route Change
        //         if (this.media.isActive(this.mobileBreakpoint)) {
        //             this._openSubject.next(false);
        //         }
        //     });
    }

    ngOnDestroy(): void {
        this.destroyed$.next();
    }

    open() {
        this._openSubject.next(true);
    }

    close() {
        this._openSubject.next(false);
    }

    toggle() {
        this._openSubject.next(!this._openSubject.getValue());
    }

    collapsedSidenav(collapsed: boolean) {
        this._collapsedSubject.next(collapsed);
    }

    expandSidenav(expand: boolean) {
        this._expandedSubject.next(expand);
    }
}
