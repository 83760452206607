import { Inject, Injectable, Optional } from "@angular/core";
import { MomentDateAdapter } from "@angular/material-moment-adapter";
import { MAT_DATE_LOCALE } from "@angular/material/core";

@Injectable()
export class MomentDateOverrideAdapter extends MomentDateAdapter {
    constructor(@Optional() @Inject(MAT_DATE_LOCALE) dateLocale: string) {
        super(dateLocale);
    }

    // parse(value: any, parseFormat: string | string[]): Moment | null {
    //     if (value && typeof value === 'string') {
    //         return moment.utc(value, parseFormat, this.locale, true);
    //     }
    //     return value ? moment.utc(value).locale(this.locale) : null;
    // }

    // set first day of week in the calendar
    getFirstDayOfWeek(): number {
        if (this.locale === "en-US") return 0; // for US return Sunday (0)
        return 1; // return Monday (1)
    }
}
