import { TranslateService } from '@ngx-translate/core';
import { ThemeService } from './_global/_theme/theme.service';
import { Component, Inject, Renderer2 } from '@angular/core';
import { DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE } from '@angular/material/core';
import { DEFAULT_DATE_FORMAT } from './_global/_configurations/default-date-format';
import { MAT_MOMENT_DATE_ADAPTER_OPTIONS } from '@angular/material-moment-adapter';
import { MomentDateOverrideAdapter } from './_global/_configurations/moment-date-override.adapter';
import * as moment from 'moment';
import { NavigationEnd, Router } from '@angular/router';
import { DOCUMENT } from '@angular/common';
import { Title } from '@angular/platform-browser';
import { environment } from 'src/environments/environment';

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    providers: [
        { provide: MAT_DATE_FORMATS, useValue: DEFAULT_DATE_FORMAT },
        {
            provide: MAT_MOMENT_DATE_ADAPTER_OPTIONS,
            useValue: { useUtc: true },
        },
        {
            provide: DateAdapter,
            useClass: MomentDateOverrideAdapter,
            deps: [MAT_DATE_LOCALE, MAT_MOMENT_DATE_ADAPTER_OPTIONS],
        },
    ],
})
export class AppComponent {
    constructor(
        private renderer: Renderer2,
        private router: Router,
        private translateService: TranslateService,
        private themeService: ThemeService,
        private title: Title,
        @Inject(DOCUMENT) private document: Document
    ) {
        // Theme initialization
        this.themeService.renderer = this.renderer;
        this.themeService.setTheme('toitsu-ng-gov');

        this.title.setTitle(environment.config.organization + ' | Booking');

        // App translation
        this.initAppTranslation();

        // scroll to top on navigation end
        this.router.events.subscribe((ev) => {
            if (ev instanceof NavigationEnd) {
                this.document.getElementById('layout-content').scrollTop = 0;
            }
        });
    }

    private initAppTranslation(): void {
        // add compatible langs
        this.translateService.addLangs(['el-EL', 'en-GB']);

        // set default lang
        // this language will be used as a fallback when a translation isn't found in the current language
        this.translateService.setDefaultLang('el-EL');

        // set language
        let language = localStorage.getItem('language') || 'el-EL';
        this.translateService.use(language);
        localStorage.setItem('language', this.translateService.currentLang);

        // update preferences on lang change
        this.translateService.onLangChange.subscribe((result) => {
            moment.locale(result.lang);
        });
    }
}
