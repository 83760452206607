import { Component, OnDestroy, OnInit } from '@angular/core';
import { environment } from 'src/environments/environment';

@Component({
    selector: 'toitsu-ng-material-header-organization',
    templateUrl: './header-organization.component.html',
    styleUrls: ['./header-organization.component.scss'],
})
export class HeaderOrganizationComponent implements OnInit, OnDestroy {
    perifereia = environment.config.organization;

    constructor() {}

    ngOnInit(): void {}

    ngOnDestroy(): void {}
}
