<div class="sidenav">

    <div *ngIf="showHeader$ | async">
        <toitsu-ng-material-sidenav-header></toitsu-ng-material-sidenav-header>
    </div>

    <div class="sidenav-content">

        <a class="sidenav-item" routerLink="/app/calendar" routerLinkActive="active">
            <div class="icon">
                <mat-icon class="material-icons-outlined">event</mat-icon>
            </div>
            <span class="description">{{'NAVIGATION.CALENDAR' | translate}}</span>
            <div class="expand-icon"></div>
        </a>

        <a class="sidenav-item" routerLink="/app/employee" routerLinkActive="active">
            <div class="icon">
                <mat-icon class="material-icons-outlined">person</mat-icon>
            </div>
            <span class="description">{{'NAVIGATION.EMPLOYEE_INFO' | translate}}</span>
            <div class="expand-icon"></div>
        </a>

        <!-- <a class="sidenav-item">
            <div class="icon">
                <span>Te</span>
            </div>
            <span class="description">Test</span>
            <div class="expand-icon"></div>
        </a> -->

    </div>
</div>
