<div class="header-user" *ngIf="user" fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="8px">
    <div class="mat-body-1" fxLayout="column" fxLayoutAlign="start end" fxHide.lt-md>
        <span>{{user.lastName}}</span>
        <span>{{user.firstName}}</span>
    </div>

    <div (clickOutside)="onClickOutside()">
        <button mat-icon-button (click)="toggleDropdown()">
            <mat-icon>person_outline</mat-icon>
        </button>

        <div class="dropdown" [@toggleDropdown]="dropdownState">
            <div class="content">
                <div class="dropdown-header">
                    <span>{{user.lastName}}</span>
                    <span>{{user.firstName}}</span>
                </div>
                <mat-divider></mat-divider>
                <div class="list">
                    <div class="list-item" matRipple routerLink="/user" (click)="toggleDropdown()">
                        <mat-icon class="list-item-icon">event</mat-icon>
                        <span>{{'BOOKING.YOUR_BOOKINGS' | translate}}</span>
                    </div>
                    <mat-divider></mat-divider>
                    <div class="list-item" matRipple (click)="logout()">
                        <mat-icon class="list-item-icon">exit_to_app</mat-icon>
                        <span>{{'GLOBAL.LOGOUT' | translate}}</span>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<!-- 
<mat-menu #appMenu="matMenu">
    <ng-template matMenuContent let-name="name">
        <span mat-menu-item>{{name}}</span>
        <button mat-menu-item>Settings</button>
        <button mat-menu-item>Log off {{name}}</button>
    </ng-template>
</mat-menu>

<button mat-icon-button [matMenuTriggerFor]="appMenu" [matMenuTriggerData]="{name: 'Sally'}">
    <mat-icon>more_vert</mat-icon>
</button>

<button mat-icon-button [matMenuTriggerFor]="appMenu" [matMenuTriggerData]="{name: 'Bob'}">
    <mat-icon>more_vert</mat-icon>
</button> -->