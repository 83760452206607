import { MatBottomSheetModule } from '@angular/material/bottom-sheet';
import { RemoveAccentsPipe } from './_pipes/remove-accents.pipe';
import { HighlightPipe } from './_pipes/highlight.pipe';
import { ClickOutsideModule } from './_util/click-outside/click-outside.module';
import { AppPageModule } from './_components/app-page/app-page.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgModule } from '@angular/core';
import { FlexLayoutModule } from '@angular/flex-layout';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatBadgeModule } from '@angular/material/badge';
import { MatButtonModule } from '@angular/material/button';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { MatCardModule } from '@angular/material/card';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatNativeDateModule, MatRippleModule } from '@angular/material/core';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatDialogModule } from '@angular/material/dialog';
import { MatDividerModule } from '@angular/material/divider';
import { MatGridListModule } from '@angular/material/grid-list';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatListModule } from '@angular/material/list';
import { MatMenuModule } from '@angular/material/menu';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatRadioModule } from '@angular/material/radio';
import { MatSelectModule } from '@angular/material/select';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatSliderModule } from '@angular/material/slider';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatSortModule } from '@angular/material/sort';
import { MatStepperModule } from '@angular/material/stepper';
import { MatTableModule } from '@angular/material/table';
import { MatTabsModule } from '@angular/material/tabs';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatTooltipModule } from '@angular/material/tooltip';
import { NgxMatSelectSearchModule } from 'ngx-mat-select-search';
import { TranslateModule } from '@ngx-translate/core';
import { LoaderModule } from './_components/loader/loader.module';
import { DateFormatPipe } from './_pipes/date-format.pipe';
import { IConfig, NgxMaskModule } from 'ngx-mask';

export const maskConfig: Partial<IConfig> | (() => Partial<IConfig>) = {
    validation: true,
};

@NgModule({
    declarations: [HighlightPipe, RemoveAccentsPipe, DateFormatPipe],
    imports: [NgxMaskModule.forRoot(maskConfig)],
    exports: [
        // Material Components
        MatInputModule,
        MatTabsModule,
        MatIconModule,
        MatListModule,
        MatButtonModule,
        MatToolbarModule,
        MatDialogModule,
        MatMenuModule,
        MatGridListModule,
        MatCardModule,
        MatSnackBarModule,
        MatTooltipModule,
        MatSliderModule,
        MatAutocompleteModule,
        MatDatepickerModule,
        MatSlideToggleModule,
        MatSidenavModule,
        MatCheckboxModule,
        MatNativeDateModule,
        MatProgressBarModule,
        MatProgressSpinnerModule,
        MatSelectModule,
        MatRippleModule,
        MatRadioModule,
        MatButtonToggleModule,
        MatTableModule,
        MatPaginatorModule,
        MatSortModule,
        MatStepperModule,
        MatDividerModule,
        MatBadgeModule,
        MatBottomSheetModule,

        // Flex Layout
        FlexLayoutModule,

        // Forms
        FormsModule,
        ReactiveFormsModule,
        NgxMatSelectSearchModule,
        NgxMaskModule,

        // Translations
        TranslateModule,

        // Toitsu Ng Material Shared
        AppPageModule,
        ClickOutsideModule,
        LoaderModule,

        // Pipes
        HighlightPipe,
        RemoveAccentsPipe,
        DateFormatPipe,
    ],
})
export class SharedModule {}
