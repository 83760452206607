<div class="header">

    <app-page-container>
        <div class="content">
            <a class="logo" routerLink="/">
                <img src="assets/images/logos/govgr-logo.svg" alt="gov">
            </a>
            <toitsu-ng-material-header-organization></toitsu-ng-material-header-organization>

            <div fxFlex></div>

            <!-- <toitsu-ng-material-header-dark-mode></toitsu-ng-material-header-dark-mode> -->
            <!-- <toitsu-ng-material-header-language></toitsu-ng-material-header-language> -->
            <toitsu-ng-material-header-user></toitsu-ng-material-header-user>
        </div>
    </app-page-container>

</div>

<div class="header-seperator"></div>