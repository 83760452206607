import { animate, state, style, transition, trigger } from '@angular/animations';

export const headerUserDropdownAnimation = trigger('toggleDropdown', [
    state(
        '*, closed',
        style({
            visibility: 'hidden',
        })
    ),
    state(
        'open',
        style({
            visibility: 'visible',
        })
    ),
    transition('closed => open', [
        style({ transform: 'translateY(-1em)', visibility: 'visible', opacity: '0' }),
        animate('.33s ease', style({ transform: 'translateY(0)', opacity: '1' })),
    ]),
    transition('open => closed', [
        style({ transform: 'translateY(0)', visibility: 'visible', opacity: '1' }),
        animate('.33s ease', style({ transform: 'translateY(-1em)', opacity: '0' })),
    ]),
]);
