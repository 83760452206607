export const DEFAULT_DATE_FORMAT = {
    parse: {
        dateInput: ['L', 'YYYY-MM-DD'],
    },
    display: {
        dateInput: 'L',
        monthYearLabel: 'MM/YYYY',
        dateA11yLabel: 'L',
        monthYearA11yLabel: 'MMMM YYYY',
    },
};
