import { SharedModule } from './../../_global/_shared.module';
import { FooterComponent } from './footer.component';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

@NgModule({
    declarations: [FooterComponent],
    imports: [CommonModule, SharedModule],
    exports: [FooterComponent],
})
export class FooterModule {}
